import React, {useEffect, useState} from 'react';
import {Button, Form, Label, Input, FormGroup} from 'reactstrap';
import axios from 'axios';
import {httpGet, backend} from '../shared/Config';

const defaultData = {
    name: '',
    rfc: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    postalCode: '',
    cfdiId: '',
    regimenFiscal: ''
}

function UpdateRegimenFiscalInline({isOpen, invoiceId, onSaved, askFacturacion}) {

    const [data, setData] = useState(defaultData);
    const [working, setWorking] = useState(false);

    function handleTextChange(attr) {
        return function (event) {
            setData({...data, [attr]: event.target.value})
        }
    }

    const [regimenFiscalesSelect, setRegimenFiscalesSelect] = useState();
    const [cfdiSelect, setCfdiSelect] = useState();

    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                const response = await httpGet(`/getCFDIs`);
                setCfdiSelect(response.data.map((cfdiId) => <option key={cfdiId.id}
                                                                    value={cfdiId.id}>{cfdiId.desc}</option>));
            } catch (e) {
            }

        }

        fetchRegimenFiscales();
    }, [isOpen])

    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                const response = await httpGet(`/getRegimenFiscales/${data.cfdiId}`);
                setRegimenFiscalesSelect(response.data.map((regimenFiscal) => <option key={regimenFiscal.id}
                                                                                      value={regimenFiscal.code}>{regimenFiscal.data}</option>));
            } catch (e) {
            }
        }

        if (data.cfdiId) {
            fetchRegimenFiscales();
        }
    }, [data.cfdiId])


    useEffect(() => {
        setWorking(true);
        if (invoiceId && invoiceId !== 0) {
            async function fetchInvoice() {
                const response = await httpGet(`/invoiceData/${invoiceId}`);
                if (response.data) {
                    setData({
                        ...response.data,
                        name: response.data.name,
                        rfc: response.data.rfc,
                        email: response.data.email,
                        postalCode: response.data.postalCode,
                        cfdi: response.data.cfdi,
                        regimenFiscal: ''
                    });
                }
                setWorking(false);
            }

            fetchInvoice();
        } else {
            setWorking(false);
        }
    }, [invoiceId]);

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await axios.post(backend('/invoiceData'), data);
            setWorking(false);
            onSaved(response.data);
        } catch (e) {
            setWorking(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit} style={{maxWidth: '600px', margin: 'auto', padding:'20px'}}>
            <h3>Contrata un paquete de documentos</h3>
            <React.Fragment>
                Por favor llena la siguiente información, <b>todos los campos son obligatorios</b>
                <br/>
                <br/>
                <FormGroup>
                    <h4>Información de la cuenta</h4>
                    <FormGroup>
                        <Label for="nombreFacturacion">Nombre de la cuenta</Label>
                        <Input type="text" id="companyName"
                               value={data.companyName}
                               onChange={handleTextChange('companyName')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="nombreFacturacion">Nombre personal</Label>
                        <Input type="text" id="nombreFacturacion" value={data.contactName}
                               onChange={handleTextChange('contactName')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Correo electrónico</Label>
                        <Input type="email" id="emailFacturacion" value={data.contactEmail}
                               onChange={handleTextChange('contactEmail')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="telefonoFacturacion">Telefono</Label>
                        <Input type="number" id="telefonoFacturacion" value={data.contactPhone}
                               onChange={handleTextChange('contactPhone')} required/>
                    </FormGroup>
                    <hr/>
                    <br/>
                    <h4>Información de facturación</h4>
                    <Label for="name">Razón social</Label>
                    <Input type="text" id="name" value={data.name} onChange={handleTextChange('name')}
                           required/>
                </FormGroup>
                <FormGroup>
                    <Label for="rfc">RFC</Label>
                    <Input type="text" id="rfc" value={data.rfc} onChange={handleTextChange('rfc')} required/>
                </FormGroup>
                <FormGroup>
                    <Label for="postalCode">Código postal</Label>
                    <Input type="text" id="postalCode" value={data.postalCode} onChange={handleTextChange('postalCode')}
                           required/>
                </FormGroup>
                <FormGroup>
                    <Label for="CFDI">Uso del CFDI</Label>
                    <Input id="cfdi" type="select" value={data.cfdiId} onChange={handleTextChange('cfdiId')} required>
                        <option value=""></option>
                        {cfdiSelect}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="regimenFiscal">Régimen fiscal</Label>
                    <Input disabled={working} id="regimenFiscal" type="select" value={data.regimenFiscal.code}
                           onChange={handleTextChange('regimenFiscal')} required>
                        <option value=""></option>
                        {regimenFiscalesSelect}
                    </Input>
                </FormGroup>
                <br/>
            </React.Fragment>
            <Button type="submit" disabled={working} color="primary">Guardar</Button>
        </Form>
    );
}

export default UpdateRegimenFiscalInline;
