import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faBuilding, faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons'
//import { ReactComponent as SpeiLogo } from '../icons/Spei_Logo.svg'
//import { ReactComponent as StripeLogo } from '../icons/Stripe_Logo.svg'
import SpeiLogo from '../icons/Spei_Logo2.png'
import StripeLogo from '../icons/Stripe_Logo2.png'
import {Label, FormGroup, Input} from 'reactstrap';

function PaymentIcon({name, value, selected, onClick}) {
    function handleClick() {
        onClick(value);
    }

    //function handleMoreInfoClick() {
    //    onMoreInfoClick(value);
   // }

    return (
        <div className={`column PlanIcon ${selected ? 'Selected' : ''}`} onClick={handleClick}>
            {
                name === "Stripe" ? <React.Fragment>
                    <img src={StripeLogo} alt="stripelogo" />
                    <div>Pago con tarjeta</div>
                </React.Fragment> : null
            }
            {
                name === "SPEI" ? <React.Fragment>
                    <img src={SpeiLogo} alt="speilogo" />
                    <div>SPEI</div>
                </React.Fragment> : null
            }
            {/*<div
                style={{color:'#007bff'}}
                onClick={handleMoreInfoClick}>Más info</div>*/}
        </div>
    )
}

function SelectPaymentType({value, subValue, type, action, onChange, pendingPayments, askFacturacion, paymentMethods}) {

    const [selected, setSelected] = useState({name:value, sub: subValue})
    const [stripeSelected, setStripeSelected] = useState(false);

    //const [speiEnabled, setSpeiEnabled] = useState(false);
    //const [stripeEnabled, setStripeEnabled] = useState(false);

    function handleClick(event) {
        if(event.target.value === "Stripe0") {
            setStripeSelected(true);
        } else {
            setStripeSelected(false);
        }

        setSelected({...selected, name: event.target.value});
    }

    function subTypeChanged(sub) {
        return function(event) {
            setSelected({...selected, [sub]: event.target.value});
        }
    }

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    useEffect(() => {
        let defaultPaymentMethod = paymentMethods[0]; // gets the first one from the list
        setSelected({...selected, name: defaultPaymentMethod});
    }, [paymentMethods]);



    return (
        <React.Fragment>
            <h5>Selecciona una forma de pago</h5>
            <div className="column" >
                {/*<PaymentIcon icon={faUser}
                    name="Stripe"
                    selected={'Stripe' === selected.name}
                    value="Stripe"
                    onClick={handleClick}></PaymentIcon>
                <PaymentIcon icon={faBuilding}
                    name="SPEI"
                    selected={'SPEI' === selected.name}
                    value="SPEI"
                    onClick={handleClick}></PaymentIcon>*/}
                {
                    action === "CONSUMABLE" ?
                        <FormGroup tag="fieldset">
                            {
                                paymentMethods.includes("SPEI") ? <React.Fragment>
                                <FormGroup style={{marginLeft: '5%', marginTop: '2%', marginBottom: '1%'}} check>
                                    <Input disabled={pendingPayments || askFacturacion} name="radio1" type="radio" value="SPEI" checked={"SPEI" === selected.name} onChange={handleClick} />{' '}
                                    <Label check>
                                        {/*<img style={{maxHeight:'25px'}} src={SpeiLogo} alt="stripelogo" />*/}
                                        Transferencia SPEI - Un solo pago (10% de descuento)
                                    </Label>
                                </FormGroup>
                                    <div style={{marginBottom: '2%'}}>
                                        En caso de usar <b>SPEI</b>, tendrás acceso a <b>10%</b> de tus {type} (o un máximo de <b>50</b>) hasta que realices el pago y envíes el comprobante.
                                    </div>
                                </React.Fragment> : null
                            }
                            {
                                paymentMethods.includes("Stripe0") ? <React.Fragment>
                                    <FormGroup style={{marginLeft: '5%', marginBottom: '3%'}} check>
                                        <Input disabled={pendingPayments || askFacturacion} name="radio1" type="radio" value="Stripe0" checked={"Stripe0" === selected.name}  onChange={handleClick} />{' '}
                                        <Label check>
                                            {/*<img style={{maxHeight:'25px'}} src={StripeLogo} alt="stripelogo" />*/}
                                            Pago con tarjeta
                                        </Label>
                                    </FormGroup>
                                </React.Fragment> : null
                            }
                        </FormGroup>
                        : <FormGroup tag="fieldset">
                            {
                                paymentMethods.includes("SPEI") ? <React.Fragment>
                                    <FormGroup style={{marginLeft: '5%', marginTop: '2%', marginBottom: '1%'}} check>
                                        <Input name="radio1" type="radio" value="SPEI" checked={"SPEI" === selected.name} onChange={handleClick} />{' '}
                                        <Label check>
                                            Transferencia SPEI
                                        </Label>
                                    </FormGroup>
                                </React.Fragment> : null
                            }
                            {
                                paymentMethods.includes("Stripe0") ? <React.Fragment>
                                    <FormGroup style={{marginLeft: '5%', marginBottom: '3%'}} check>
                                        <Input name="radio1" type="radio" value="Stripe0" checked={"Stripe0" === selected.name}  onChange={handleClick} />{' '}
                                        <Label check>
                                            {/*<img style={{maxHeight:'25px'}} src={StripeLogo} alt="stripelogo" />*/}
                                            Pago con tarjeta
                                        </Label>
                                    </FormGroup>
                                </React.Fragment> : null
                            }
                        </FormGroup>
                }

            </div>
        </React.Fragment>
    )
}

export default SelectPaymentType
