import React, {useState, useEffect} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import SelectPlan from '../shared/SelectPlan'
import BuyGlobalDocumentsModal from './BuyGlobalDocumentsModal'
import CuentasCorporativasModal from '../plans/CuentasCorporativasModal';
/*import StripeSubscriptionModal from '../payments/StripeSubscriptionModal';
import SelectPaymentType from '../payments/SelectPaymentType';
import ConfirmationModal from '../shared/ConfirmationModal';

import MoneyFormat from '../shared/MoneyFormat'*/

const defaultData = {
    organizationName: '',
    plan: 'CORPORATIVO',
    paymentType: 'SPEI',
    companyName: '',
    factura: false,
    razonSocial: '',
    rfc: '',
    cp: '',
    email: '',
    cfdi: 'Gastos en general',
    regimenFiscal: ''
}

function OrganizationModal({isOpen, onCancel, onSaved, alreadyExists, companyActive, mode, companyName, companyNameNotUpdated, askFacturacion, clientType, tenant}) {

    const [corporativoUpgrade, setCorporativoUpgrade] = useState(false);
    const [paymentNeeded, setPaymentNeeded] = useState(true);
    const [titleMessage, setTitleMessage] = useState('');

    const [org, setOrg] = useState(defaultData)
    const [working, setWorking] = useState(false);
    //const [showConfModal, setShowConfModal] = useState(false);

    //const [askFacturacion, setAskFacturacion] = useState(false);
    //const [buttonMessage, setButtonMessage] = useState('Proceder al pago');

    const [corpoPlanCost, setCorpoPlanCost] = useState(0);
    const [personalPlanCost, setPersonalPlanCost] = useState(0);

    const [error, setError] = useState('');    /*const [subModal, setSubModal] = useState({
        open: false
    });*/

    //const [regimenFiscales, setRegimenFiscales] = useState({});

    const [buyState, setBuyState] = useState({
        subType: '',
        newOrgId: null,
        planCost: 0
    });

    const [userEditedAccountName, setUserEditedAccountName] = useState(false);

    function handleCancel() {
        setWorking(false);
        setOrg(defaultData);
        onCancel();
        setError('');
    }


    function handleOrgNameChange(attr) {
        return function(event) {
            if(event.target.value.length < 25) {
                setOrg({...org, [attr]:event.target.value})
            }
        }
    }

    function handleAccountNameChange(attr) {
        return function(event) {
            setUserEditedAccountName(true);
            if(event.target.value.length < 25) {
                setOrg({...org, [attr]:event.target.value})
            }
        }
    }

    function handleRazonSocialChange(attr) {
        return function(event) {
            let value = event.target.value;
            if(!userEditedAccountName) {
                let splitValue = value.split(" ");
                setOrg({...org, companyName:splitValue[0], razonSocial: value});
            } else {
                setOrg({...org, razonSocial: value});
            }
        }
    }

    function handleTextChange(attr) {
        return function(event) {
            setOrg({...org, [attr]:event.target.value})
        }
    }

    const [globalDocumentsModal, setGlobalDocumentsModal] = useState({
        open: false
    })

    const [cuentasCorporativasModal, setCuentasCorporativasModal] = useState({
        open: false
    })

    function handleGlobalDocumentsModalCancel() {
        setGlobalDocumentsModal({open:false})
        setWorking(false);
    }

    function handleGlobalDocumentsAdded() {
        setGlobalDocumentsModal({open:false});
        //handleUpgrade();
        onSaved();
    }

    function handleCuentasCorporativasClick() {
        setCuentasCorporativasModal({open:true});
    }

    function handleCuentasCorporativasClose() {
        setCuentasCorporativasModal({open:false});
    }

    /*function handleInvoice(invoiceDataId) {

        console.log("invoice change:" + invoiceDataId);
        // setOrg({...org, invoiceDataId})
        setOrg((current) => {
            const json = JSON.stringify(current);
            console.log("invoice:" +json);
            return {...current, invoiceDataId}
        })
    }*/

    /*function handleSupportDataChange(supportDataId) {

        console.log("support change:" +supportDataId);

        // setOrg({...org, supportDataId})
        setOrg((current) => {
            const json = JSON.stringify(current);
            console.log("support:" +json);
            return {...current, supportContactId: supportDataId}
        })
    }*/

    function handlePlanChange(plan) {
        setOrg({...org, plan})

        // If the plan is PERSONAL or first CORPORATIVO, we expect a payment to be made
        // plan === "PERSONAL" ||
        if(corporativoUpgrade) {
            setPaymentNeeded(true);
        } else {
            setPaymentNeeded(false);
        }
    }

    /*useEffect(() => {

    }, [buyState]);*/


    /*async function handleUpgrade(){
        setWorking(true);

        const response = await httpPost('/orgs', {org});
        if(response) {
            setWorking(false);
            onSaved(response.data.data);
        }
    }*/

    /*async function handleSpeiPurchase(){
        if(!org.contract || org.contract === '') {
            return;
        }

        setWorking(true);
        try {
            const response = await httpPost('/orgs', {org});
            setWorking(false);
            if(response.data.success) {
                onSaved(response.data.data);

            } else {
                console.error(response.data.error);
            }
        } catch(e) {
            setWorking(false);
        }
    }*/

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setError('');

        /*if(org.plan === "CORPORATIVO" && ) {

        }*/

        setWorking(true);

        if(org.plan === "CORPORATIVO" || org.plan === "EMPRESARIAL") {

            if(mode === "PARTIAL" && (!org.organizationName || org.organizationName === '')) {
                return;
            }

            if(paymentNeeded) {
                setBuyState({...buyState, subType: org.plan, planCost: corpoPlanCost});
                async function preSave() {
                    const response = await httpPost('/orgs/saveData/preSaveOrgData', {org});
                    if(response.data.status === "success") {
                        setGlobalDocumentsModal({...globalDocumentsModal, open:true});
                    }
                    setWorking(false);
                }
                preSave();
                //setGlobalDocumentsModal({...globalDocumentsModal, open:true});
            } else {
                const response = await httpPost('/orgs', {org});
                setWorking(false);
                if(response) {
                    if(response.data.data.error) {
                        setError(response.data.data.error);
                    } else {
                        onSaved(response.data.data);
                    }
                }
            }
        }
        setWorking(false);
        /*if(corporativoUpgrade && org.plan === "CORPORATIVO") {
            if(org.paymentType === "Stripe0") {
                setBuyState({...buyState, subType: org.plan, planCost: corpoPlanCost});
                //setSubModal({open:true});
                setGlobalDocumentsModal({open:true});
            } else if(org.paymentType === "SPEI") {
                /*const response = await httpPost('/orgs', {org});
                setWorking(false);
                onSaved(response.data.data);*/
            /*}
        } else if(!corporativoUpgrade && org.plan === "CORPORATIVO") {
            const response = await httpPost('/orgs', {org});
            setWorking(false);
            onSaved(response.data.data);
        } else {
            if(org.plan === "PERSONAL") {
                if(org.paymentType === "Stripe0") {
                    setBuyState({...buyState, subType: org.plan, planCost: personalPlanCost});
                    setGlobalDocumentsModal({open:true});
                } else if(org.paymentType === "SPEI") {
                    /*const response = await httpPost('/orgs', {org});
                    setWorking(false);
                    onSaved(response.data.data);*/
                /*}
            }*/
            /*try {
                const response = await httpPost('/orgs', {org});
                setWorking(false);

                if(response.data.data.error) {
                    if(response.data.data.error === "personal_already_exists") {
                        alreadyExists(response.data.data.orgId);
                    }
                } else {
                    if(response.data.success) {
                        if(org.plan === "PERSONAL" && org.paymentType === "Stripe") {
                            setBuyState({...buyState, subType: org.plan, newOrgId: response.data.data.id, planCost: personalPlanCost});
                            setSubModal({open:true});
                        } else {
                            onSaved(response.data.data);
                        }
                    } else {
                        console.error(response.data.error);
                    }
                }
            } catch(e) {
                setWorking(false);
            }*/
        //}
    }

    /*useEffect(() => {
        //(buyState.subType === "PERSONAL" || ) &&
        //if(org.paymentType === "Stripe") {
        //    setSubModal({open:true}); // buystate has been updated with the plan that the user wants to sub to, open modal
        //}
    }, [buyState])*/

    /*function cancelSub() {
        setSubModal({open:false}); // closes the modal, the user won't be able to open the department until the sub is complete
        setWorking(false);
        setOrg(defaultData);
        onCancel();
    }

    function successSub(orgId) {
        setSubModal({open:false});
        //if(buyState.newOrgId) {
        //    onSaved(buyState.newOrgId);
        //}
        onSaved(orgId);
    }*/

    useEffect(() => {
        setOrg(orgTemp => {
            const orgValue = orgTemp;
            orgValue.factura = askFacturacion;
            return orgValue;
        });
        /*async function checkIfInvoiceData() {
            try {
                const response = await httpGet('/invoiceData/getGlobalInvoicePresent');
                if(response.data && response.data.status) {
                    if(response.data.status === "no_global_invoice") {
                        setAskFacturacion(true);
                        setOrg(orgTemp => {
                            const orgValue = orgTemp;
                            orgValue.factura = true;
                            return orgValue;
                        });
                    }
                }
            } catch(e) {
            }
        }

        //getCompanyN();
        checkIfInvoiceData();*/
    }, [askFacturacion]);

    useEffect(() => {
        setCorporativoUpgrade(false);
        switch(mode) {
            case "ALL": {
                setTitleMessage("Contratar servicio");
                setPaymentNeeded(true);
                setCorporativoUpgrade(true);
                break;
            }
            case "PARTIAL" :{
                setTitleMessage("Agregar departamento nuevo");
                setPaymentNeeded(false);
                setOrg(orgTemp => {
                    const orgValue = orgTemp;
                    orgValue.plan = 'CORPORATIVO';
                    return orgValue;
                });
                setCorporativoUpgrade(false);
                break;
            }
            case "PARTIAL_PERSONAL": {
                setTitleMessage("Convertir cuenta a 'ON DEMAND' y agregar departamento nuevo");
                setPaymentNeeded(true);
                setOrg(orgTemp => {
                    const orgValue = orgTemp;
                    orgValue.plan = 'CORPORATIVO';
                    return orgValue;
                });
                setCorporativoUpgrade(true);
                break;
            }
            case "PARTIAL_EMPRESARIAL" :{
                setTitleMessage("Agregar departamento nuevo");
                setPaymentNeeded(false);
                setOrg(orgTemp => {
                    const orgValue = orgTemp;
                    orgValue.plan = 'EMPRESARIAL';
                    return orgValue;
                });
                setCorporativoUpgrade(false);
                break;
            }
            case "ONLY_INFO": {
                setTitleMessage("Contratar servicio");
                setPaymentNeeded(false);
                setOrg(orgTemp => {
                    const orgValue = orgTemp;
                    orgValue.plan = 'CORPORATIVO';
                    return orgValue;
                });
                setCorporativoUpgrade(true);
                break;
            }
            default: {
                break;
            }
        }
    }, [mode, isOpen]);

    /*function handlePaymentTypeChange(type) {
        setOrg({...org, paymentType: type.name})
    }

    function handleConfCancel() {
        setWorking(false);
        setShowConfModal(false);
        setOrg(defaultData);
    }*/

    return (
        <Modal isOpen={isOpen}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    {titleMessage}
                </ModalHeader>
                <ModalBody>
                    {
                        corporativoUpgrade && org.plan === "CORPORATIVO" ? <React.Fragment>
                        {
                            mode === "ONLY_INFO" ? <React.Fragment>
                                Tan solo requerimos unos datos extras para terminar. Al terminar este proceso, convertirá su cuenta <b>DEMO</b> en una <b>ON DEMAND</b>. Este cambio es permanente.
                            </React.Fragment> : null
                        }
                        {
                            mode === "ALL" ? <React.Fragment>
                                Al terminar este proceso, convertirá su cuenta DEMO en una ON DEMAND. Este cambio es permanente.
                                <br/>
                                <br/>
                                No existe un costo por convertir su cuenta a ON DEMAND. Sin embargo, requiere realizar una compra de documentos.
                            </React.Fragment> : null
                        }
                        {/*
                            mode === "PARTIAL_PERSONAL" ? <React.Fragment>
                                Al crear un departamento, convertirá tu cuenta PERSONAL en una CORPORATIVA. Su suscripción PERSONAL se cancelará y no se renovará al próximo corte. Este cambio es permanente.
                                <br/>
                                <br/>
                            </React.Fragment> : null
                        */}
                        {/*El costo de convertir su cuenta a CORPORATIVA es de <b><MoneyFormat>{corpoPlanCost}</MoneyFormat>MXN (incluye IVA)</b>. <b>100 documentos, 100 estampillas</b> y podrá crear hasta 5 departamentos de manera gratuita.*/}
                        <br/>
                        <br/>
                        </React.Fragment> : null
                    }
                    {/*
                        org.plan === "PERSONAL" ? <React.Fragment>
                            El costo mensual de una cuenta PERSONAL es de <b><MoneyFormat>{personalPlanCost}</MoneyFormat>MXN (incluye IVA)</b>. Incluye 20 documentos mensuales.
                            <br/>
                            <br/>
                        </React.Fragment> : null
                    */}
                    {
                        !companyActive ?
                            <p style={{fontWeight: 'bold', color: 'red'}}>Su compañía ha sido desactivada. Por favor, contáctenos para más información.</p>
                         : null
                    }
                    {
                        mode !== "PARTIAL" && mode !== "PARTIAL_EMPRESARIAL" ?
                            <div>
                                <SelectPlan mode={mode} value={org.plan} onChange={handlePlanChange} personalPrice={personalPlanCost}/>
                            </div>
                         : null
                    }
                    {
                        mode === "ALL" ?
                            <div style={{color:'#007bff', cursor: 'pointer', marginBottom: '15px'}}
                            onClick={handleCuentasCorporativasClick}>Cuentas corporativas</div>
                         : null
                    }
                    {/*
                        askFacturacion ? <React.Fragment>
                            <h5>Datos de facturación</h5>
                            <Label for="razonSocial">Razon social</Label>
                            <Input type="razonSocial" id="razonSocial" value={org.razonSocial} onChange={handleRazonSocialChange('razonSocial')} required/>
                            <br/>
                            <Label for="rfc">RFC</Label>
                            <Input type="text" id="rfc" value={org.rfc} onChange={handleTextChange('rfc')} required/>
                            <br/>
                            <Label for="cp">Código postal</Label>
                            <Input type="number" id="cp" value={org.cp} onChange={handleTextChange('cp')} required/>
                            <br/>
                            <Label for="email">Correo electrónico</Label>
                            <Input type="email" id="email" value={org.email} onChange={handleTextChange('email')} required/>
                            <br/>
                            <Label for="cfdi">Uso del CFDI</Label>
                            <Input id="cfdi" type="select" value={org.cfdi} onChange={handleTextChange('cfdi')} required>
                                <option value="Gastos en general">Gastos en general</option>
                                <option value="Adquisición de mercancías">Adquisición de mercancías</option>
                                <option value="Devoluciones, descuentos o bonificaciones">Devoluciones, descuentos o bonificaciones</option>
                                <option value="Construcciones">Construcciones</option>
                                <option value="Mobiliario y equipo de oficina por inversiones">Mobiliario y equipo de oficina por inversiones</option>
                                <option value="Equipo de transporte">Equipo de transporte</option>
                                <option value="Equipo de cómputo y accesorios">Equipo de cómputo y accesorios</option>
                                <option value="Dados, troqueles, moldes, matrices y herramental">Dados, troqueles, moldes, matrices y herramental</option>
                                <option value="Comunicaciones telefónicas">Comunicaciones telefónicas</option>
                                <option value="Comunicaciones satelitales">Comunicaciones satelitales</option>
                                <option value="Otra maquinaria y equipo">Otra maquinaria y equipo</option>
                                <option value="Honorarios médicos, dentales y gastos hospitalarios.">Honorarios médicos, dentales y gastos hospitalarios</option>
                                <option value="Gastos médicos por incapacidad o discapacidad">Gastos médicos por incapacidad o discapacidad</option>
                                <option value="Gastos funerales">Gastos funerales</option>
                                <option value="Donativos">Donativos</option>
                                <option value="Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)">Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)</option>
                                <option value="Aportaciones voluntarias al SAR">Aportaciones voluntarias al SAR</option>
                                <option value="Primas por seguros de gastos médicos.">Primas por seguros de gastos médicos</option>
                                <option value="Gastos de transportación escolar obligatoria.">Gastos de transportación escolar obligatoria</option>
                                <option value="Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</option>
                                <option value="Pagos por servicios educativos (colegiaturas)">Pagos por servicios educativos (colegiaturas)</option>
                                <option value="Por definir">Por definir</option>
                            </Input>
                            <br/>
                            <Label for="regimenFiscal">Régimen fiscal</Label>
                            <Input id="regimenFiscal" type="select" value={org.regimenFiscal} onChange={handleTextChange('regimenFiscal')} required>
                                <option value=""></option>
                                {regimenFiscalesSelect}
                            </Input>
                            <br/>
                        </React.Fragment> : null
                    */}
                    {
                        org.plan === "CORPORATIVO" || org.plan === "EMPRESARIAL" ? <React.Fragment>
                            {
                                companyNameNotUpdated ?
                                    <FormGroup>
                                        <Label for="orgName">Nombre que le desea dar a su cuenta de Firmamex</Label>
                                        <Input type="text" id="orgName" value={org.companyName} onChange={handleAccountNameChange('companyName')} required/>
                                        <br/>
                                    </FormGroup>
                                : null
                            }
                            {
                                mode === "PARTIAL" || mode === "PARTIAL_EMPRESARIAL" ?
                                    <FormGroup>
                                        <Label for="orgName">Nombre del departamento a crear</Label>
                                        <Input type="text" id="orgName" value={org.organizationName} onChange={handleOrgNameChange('organizationName')} required/>
                                        {/*
                                            org.organizationName ? <React.Fragment>
                                                Este sera el nombre final del departamento:
                                                <br/>
                                                {
                                                    companyNameNotUpdated ? <React.Fragment>
                                                        {org.companyName}-{org.organizationName}
                                                    </React.Fragment> :
                                                    <React.Fragment>
                                                        {companyName}-{org.organizationName}
                                                    </React.Fragment>
                                                }
                                            </React.Fragment> : null
                                        */}
                                        <br/>
                                    </FormGroup>
                                 : null
                            }
                        </React.Fragment> : null
                    }
                    {/*
                        paymentNeeded ? <React.Fragment>
                            <div>
                                <SelectPaymentType value={org.paymentType} subValue="" action="UPGRADE" onChange={handlePaymentTypeChange} />
                            </div>
                        </React.Fragment> : null
                */}
                {
                    error ? <span style={{fontWeight: 'bold', color: 'red'}}>{error}</span> : null
                }
                </ModalBody>
                <ModalFooter>
                    {
                        paymentNeeded ?
                         <Button type="submit" disabled={working || !companyActive} color="primary">Ir a compra de documentos</Button>
                         : <Button type="submit" disabled={working || !companyActive} color="primary">Continuar</Button>
                    }
                    {/*
                        paymentNeeded ? <React.Fragment>
                            <Button type="submit" disabled={working || !org.paymentType || !companyActive} color="primary">
                                {
                                    org.paymentType ? <React.Fragment>Seleccione un método de pago</React.Fragment> :
                                    <React.Fragment>
                                        {
                                            org.paymentType === "SPEI" ? <React.Fragment>Contratar</React.Fragment> : null
                                        }
                                        {
                                            org.paymentType === "Stripe0" ? <React.Fragment>Proceder al pago</React.Fragment> : null
                                        }
                                    </React.Fragment>
                                }
                            </Button>
                        </React.Fragment> : <React.Fragment>
                            <Button type="submit" disabled={working || !companyActive} color="primary">Crear departamento</Button>
                        </React.Fragment>
                            */}
                    <Button disabled={working} color="secondary" onClick={handleCancel}>Cancelar</Button>
                </ModalFooter>
            </Form>
            <BuyGlobalDocumentsModal
                    isOpen={globalDocumentsModal.open}
                    onCancel={handleGlobalDocumentsModalCancel}
                    onSaved={handleGlobalDocumentsAdded}
                    companyActive={companyActive}
                    tenant={tenant}
                    type="UPGRADE"
                    org={org}
                />
            <CuentasCorporativasModal
                isOpen={cuentasCorporativasModal.open}
                onClose={handleCuentasCorporativasClose}
                />
        </Modal>
    )
}

export default OrganizationModal;
