import React, {useState, useEffect, useRef} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {
    Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, Spinner, Table
} from 'reactstrap';
import MoneyFormat from '../shared/MoneyFormat';
import ConfirmationModal from '../shared/ConfirmationModal';
import StripeDocumentsModal from '../payments/StripeDocumentsModal';
import SelectPaymentType from '../payments/SelectPaymentType';
import 'rc-slider/assets/index.css';
import EmailNoticeSPEI from '../payments/EmailNoticeSPEI';

const defaultState = {
    totalGlobalDocuments: 0,
    monthly: false,
    paymentType: 'SPEI',
    sub: 'Stripe0',
    mode: 'ONLY_DOCS'
    //timestamps: false
}

function BuyGlobalDocumentsInline({
                                      isOpen,
                                      onCancel,
                                      onSaved,
                                      companyActive,
                                      type,
                                      org,
                                      pendingPayments,
                                      askFacturacion,
                                      tenant
                                  }) {

    const [processingPayment, setProcessingPayment] = useState(false);
    const [error, setError] = useState('');

    const [showPaymentMethod, setShowPaymentMethod] = useState(true);

    const [working, setWorking] = useState(false);
    const [calcWorking, setCalcWorking] = useState(false);

    const [minimumNotReached, setMinimumNotReached] = useState(false);
    const [showConfModal, setShowConfModal] = useState(false);

    const [minimumCost, setMinimumCost] = useState(10); // SPEI minimum is $10 pesos
    const [months, setMonths] = useState(6);
    const [discount, setDiscount] = useState(0);

    const [buyConcept, setBuyConcept] = useState('Comprar paquete de documentos');
    const [buyConceptDetail, setBuyConceptDetail] = useState('documentos');

    const [paymentModal, setPaymentModal] = useState({
        open: false
    });

    const interval = useRef(setTimeout(() => {
    }, 0));


    const [buyState, setBuyState] = useState({
        totalGlobalDocuments: 0,
        monthly: false,
        paymentType: '',
        sub: 'Stripe0',
        type: type
        //timestamps: false
    });

    const [resultState, setResultState] = useState({
        precioUnitario: 0,
        precioUnitarioMasIva: 0,
        precioPaquete: 0,
        precioPaqueteMasIva: 0,
        precioTotal: 0,
        precioTotalIva: 0,
        mensualidades: 0,
        mensualidadesMasIva: 0,
        pagoUnaSolaExhibicion: 0,
        pagoUnaSolaExhibicionMasIva: 0,
        precioUnitarioTimestampsIva: 0,
        precioPaqueteTimestampsIva: 0
    });

    const [noticeModal, setNoticeModal] = useState({
        open: false
    });


    function handleBuyStateChange(name) {
        return function (event) {
            let value = Math.max(parseInt(event.target.value), 0)
            if (value < 1000000000) {
                setBuyState({...buyState, [name]: value});
            }
        }
    }


    useEffect(() => {
        if (buyState.totalGlobalDocuments > 0) {
            setCalcWorking(true);

            interval.current = setTimeout(() => {
                async function calculate() {
                    try {
                        const response = await httpPost('/calculateTotalDocuments', {
                            buyState
                        });

                        if (response) {
                            setResultState(previousState => {
                                return {
                                    ...previousState,
                                    precioUnitario: response.data.data.precioUnitario,
                                    precioUnitarioMasIva: response.data.data.precioUnitarioIva,
                                    precioPaquete: response.data.data.precioPaquete,
                                    precioPaqueteMasIva: response.data.data.precioPaqueteIva,
                                    precioTotal: response.data.data.total,
                                    precioTotalIva: response.data.data.totalIva,
                                    mensualidades: response.data.data.mensualidad,
                                    mensualidadesMasIva: response.data.data.mensualidadIva,
                                    pagoUnaSolaExhibicion: response.data.data.soloPago,
                                    pagoUnaSolaExhibicionMasIva: response.data.data.soloPagoIva,
                                    precioUnitarioTimestampsMasIva: response.data.data.precioUnitarioTimestampsIva,
                                    precioPaqueteTimestampsMasIva: response.data.data.precioPaqueteTimestampsIva
                                }

                            });
                        }

                        checkMininumThreshold(response.data.data.totalIva);
                        /*if(buyState.totalGlobalDocuments < 1000) {
                            setMonthlyMininumNotReached(true);
                        } else {
                            setMonthlyMininumNotReached(false);
                        }

                        if(buyState.totalGlobalDocuments < 50) {
                            setMininumNotReached(true);
                        } else {
                            setMininumNotReached(false);
                        }*/

                        //setChecked(true);
                        setCalcWorking(false);
                    } catch (e) {
                    }
                }

                calculate();
            }, 1000);
            /*} else {
                setCalcWorking(false);
                setMinimumDocuments(false);
            }*/
        } else {
            setResultState(previousState => {
                return {
                    ...previousState,
                    precioUnitario: 0,
                    precioUnitarioMasIva: 0,
                    precioPaquete: 0,
                    precioPaqueteMasIva: 0,
                    precioTotal: 0,
                    precioTotalIva: 0,
                    mensualidades: 0,
                    mensualidadesMasIva: 0,
                    pagoUnaSolaExhibicion: 0,
                    pagoUnaSolaExhibicionMasIva: 0
                }
            });
            //setMininumNotReached(true);
        }

        return () => {
            clearTimeout(interval.current);
        };
    }, [buyState]);

    async function payItem(event) {
        event.preventDefault();
        event.stopPropagation();

        setError('');
        //let org = buyState.org;
        if (buyState.paymentType.includes("Stripe")) {
            // setPaymentModal({...paymentModal, open: true});
            const response = await httpPost(`/payments/documents/intentStripePayment`, {buyState})
            if (response.data.error) {
                setError(response.data.error);
            } else if(response.data.checkoutUrl) {
                window.location.href = response.data.checkoutUrl;
            }
        } else {
            handleSpeiPay();
        }
    }

    function modalClose() {
        setPaymentModal({...paymentModal, open: false});
    }

    function stripeApproved() {
        setPaymentModal({...paymentModal, open: false}); // message confirmation
        setBuyState(defaultState);
        onSaved();
    }

    useEffect(() => {
        async function processSpeiPayment() {
            try {
                //setShowConfModal(false);
                const response = await httpPost(`/orgs/globalDocuments`, {
                    buyState,
                    org
                })
                const {success, error} = response.data;
                if (success) {
                    setNoticeModal({...noticeModal, open: true});
                    //setBuyState(defaultState);
                    //setShowConfModal(false);
                    // onSaved();
                } else {
                    console.log(error);
                }
                setWorking(false);
                setProcessingPayment(false);
            } catch (e) {
                setWorking(false);
                setProcessingPayment(false);
            }

        }

        if (processingPayment) {
            processSpeiPayment();
        }
    }, [processingPayment])


    function handleSpeiPurchaseClick() {
        if (showConfModal) {
            setProcessingPayment(true);
        }
    }

    function noticeClose() {
        setBuyState(defaultState);
        setShowConfModal(false);
        setNoticeModal({...noticeModal, open: false});
        onSaved('spei');
    }

    function handleConfCancel() {
        setWorking(false);
        setShowConfModal(false);
    }

    function handleSpeiPay(event) {
        setWorking(true);
        setShowConfModal(true);
    }

    function handleCancel() {
        setBuyState(defaultState);

        onCancel();
    }

    function handlePaymentTypeChange(type) {
        //setPaymentType(type);
        let monthly = false;
        let minimum = 10;
        let months = 6;
        let discount = 0;

        let paymentTypeCheck = "SPEI";

        switch (type.name) {
            case "SPEI":
                discount = 10;
                break;
            case "Stripe0":
                switch (type.sub) {
                    case "Stripe0":
                        paymentTypeCheck = "Stripe0";
                        discount = 6;
                        break;
                    case "Stripe6":
                        paymentTypeCheck = "Stripe6";
                        discount = 3;
                        minimum = 600;
                        monthly = true;
                        break;
                    case "Stripe12":
                        paymentTypeCheck = "Stripe12";
                        minimum = 1200;
                        monthly = true;
                        months = 12;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        checkMininumThreshold(resultState.precioTotalIva);
        setMinimumCost(minimum);
        setMonths(months);
        setDiscount(discount);

        setBuyState({...buyState, paymentType: paymentTypeCheck, monthly: monthly});
    }

    useEffect(() => {
        if(tenant?.paymentMethods?.length > 1) {
            setShowPaymentMethod(true);
        }
    }, [tenant]);

    function checkMininumThreshold(totalIva) {
        if (totalIva < minimumCost && totalIva !== 0) {
            setMinimumNotReached(true);
        } else {
            setMinimumNotReached(false);
        }
    }

    return (
        <React.Fragment>
            <Form onSubmit={payItem} style={{maxWidth: '600px', margin: 'auto', padding:'20px'}}>
                <h3>
                    Contrata tu primer paquete de documentos
                </h3>
                <div>
                    <p>Una vez completada la compra podrás generar departamentos e invitar a otros usuarios</p>
                    <p>
                        <b>Todos los precios tienen IVA incluido.</b> Los documentos expiran <b>15</b> meses después de su compra.
                    </p>
                </div>
                <FormGroup tag="fieldset">
                    <FormGroup>
                        <Label for="totalGlobalDocuments">Cantidad de documentos: </Label>
                        <Input disabled={pendingPayments || askFacturacion} type="number" id="totalGlobalDocuments"
                               value={buyState.totalGlobalDocuments.toString()}
                               onChange={handleBuyStateChange('totalGlobalDocuments')} required/>
                    </FormGroup>
                    {
                        !companyActive ? <React.Fragment>
                            <p style={{fontWeight: 'bold', color: 'red'}}>Su compañía ha sido desactivada. Por favor,
                                contáctenos para más información.</p>
                        </React.Fragment> : null
                    }
                    {
                        calcWorking ? <React.Fragment>
                            { /* calcWorking */}
                            <div className="d-flex justify-content-center">
                                <Spinner color="primary"/>
                            </div>
                        </React.Fragment> : null
                    }
                    Precio unitario: <MoneyFormat>{resultState.precioUnitarioMasIva}</MoneyFormat> { tenant.currency }
                    <br/>
                    El costo unitario se reduce dependiendo de la cantidad de documentos y la forma de pago.
                    <br/>
                </FormGroup>
                <div>
                    <SelectPaymentType value={buyState.paymentType} subValue={buyState.sub} action="CONSUMABLE"
                                       type="documentos" onChange={handlePaymentTypeChange}
                                       pendingPayments={pendingPayments} askFacturacion={askFacturacion}
                                       paymentMethods={tenant.paymentMethods}/>
                </div>
                <FormGroup>
                    Precio total: <MoneyFormat>{resultState.precioTotalIva}</MoneyFormat> { tenant.currency }
                    {
                        buyState.monthly ? <React.Fragment>
                            <br/>
                            {/* <MoneyFormat>{resultState.mensualidades}</MoneyFormat> - Con IVA: */}
                            {/*<MoneyFormat>{resultState.mensualidadesMasIva}</MoneyFormat>  */}
                            Mensualidades: <MoneyFormat>{resultState.mensualidadesMasIva}</MoneyFormat> { tenant.currency }
                            <br/>
                        </React.Fragment> : null
                    }
                    {
                        minimumNotReached ? <React.Fragment>
                            <br/>
                            <p style={{fontWeight: 'bold', color: 'red'}}>La compra mínima a <b>{months}</b> meses, es
                                de <MoneyFormat>{minimumCost}</MoneyFormat> { tenant.currency } </p>
                        </React.Fragment> : null
                    }
                </FormGroup>
                {
                    error ? <React.Fragment>
                        <span style={{fontWeight: 'bold', color: 'red'}}>{error}</span>
                        <br/>
                        <br/>
                    </React.Fragment> : null
                }
                <Button type="submit"
                        disabled={calcWorking || working || minimumNotReached || !buyState.paymentType || !companyActive || resultState.precioTotalIva <= 0 || pendingPayments}
                        color="primary">Pagar</Button>
            </Form>
            <StripeDocumentsModal
                isOpen={paymentModal.open}
                buyState={buyState}
                months={months}
                discount={discount}
                onCancel={modalClose}
                onStripeApproved={stripeApproved}
                org={org}
                type={type}
            />
            <ConfirmationModal isOpen={showConfModal}
                               onCancel={handleConfCancel}
                               onOk={handleSpeiPurchaseClick}
                               title={buyConcept}>
                <p>
                    Estás por comprar un paquete
                    de <b>{buyState.totalGlobalDocuments} {buyConceptDetail}</b> usando <b>SPEI</b>. Al
                    seleccionar <b>'Comprar'</b> se
                    asignarán los {buyConceptDetail} y tendras 3 días hábiles para realizar el pago por <b>SPEI</b></p>
                <p>¿Deseas continuar?</p>
                <EmailNoticeSPEI
                    isOpen={noticeModal.open}
                    onClose={noticeClose}
                />
            </ConfirmationModal>
        </React.Fragment>
    )
}

export default BuyGlobalDocumentsInline
