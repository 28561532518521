import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import React, {useState} from "react";
import {httpPost} from "../shared/Config";
import MoneyFormat from "../shared/MoneyFormat";
import SelectPaymentType from "../payments/SelectPaymentType";
import ConfirmationModal from "../shared/ConfirmationModal";
import EmailNoticeSPEI from "../payments/EmailNoticeSPEI";

const debounce = (mainFunction, delay) => {
    // Declare a variable called 'timer' to store the timer ID
    let timer;

    // Return an anonymous function that takes in any number of arguments
    return function (...args) {
        // Clear the previous timer to prevent the execution of 'mainFunction'
        clearTimeout(timer);

        // Set a new timer that will execute 'mainFunction' after the specified delay
        timer = setTimeout(() => {
            mainFunction(...args);
        }, delay);
    };
};

const calcTotal = debounce(async function (value, callback) {
    const res = await httpPost('/calculateTotalVerifications', value)
    callback(res.data)
}, 1000)

function BuyGlobalIdentityVerificationsModal({isOpen, onCancel, tenant, onSaved}) {

    const [paymentType, setPaymentType] = useState('SPEI');
    const [amountSelected, setAmountSelected] = useState(0);
    const [prices, setPrices] = useState({});
    const [working, setWorking] = useState(false);
    const [showSpeiModal, setShowSpeiModal] = useState(false);
    const [noticeModal, setNoticeModal] = useState(false);
    const [error, setError] = useState(null);

    async function handlePaySubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        if (paymentType === 'Stripe0') {
            const response = await httpPost(`/payments/verifications/intentStripePayment`, {
                verifications: amountSelected,
                paymentType
            })
            if (response.data.error) {
                setError(response.data.error);
            } else if (response.data.checkoutUrl) {
                window.location.href = response.data.checkoutUrl;
            }
        } else {
            setShowSpeiModal(true);
        }
    }

    async function handleSpeiSubmit() {
        setWorking(true);
        setShowSpeiModal(false);
        try {
            const res = await httpPost(`/orgs/globalVerifications`, {
                verifications: amountSelected,
                paymentType
            })
            const {success, error} = res.data;
            if(success) {
                setNoticeModal(true);
            } else {
                console.log(error);
            }
        } catch(e) {

        }
        setWorking(false)
    }

    function handleSpeiCancel() {
        setShowSpeiModal(false);
    }

    function handleCancel() {
        onCancel();
    }

    function handleVerificationsAmountChange(paymentType, amount) {
        setWorking(true)
        calcTotal({
            paymentType,
            verifications: amount
        }, r => {
            if (r.success) {
                setPrices(r.data);
            }
            setWorking(false)
        });
    }

    function handlePaymentTypeChange(type) {
        setPaymentType(type.name);
        handleVerificationsAmountChange(type.name, amountSelected);
    }

    return (
        <Modal isOpen={isOpen}>
            <Form onSubmit={handlePaySubmit}>
                <ModalHeader>
                    Comprar verificaciones
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div style={{marginBottom: '2%'}}>
                            <b>Todos los precios tienen IVA incluido.</b> Las verificaciones
                            que compres expiran <b>15</b> meses después de tu compra.
                        </div>
                    </div>
                    <FormGroup tag="fieldset">
                        <Label for="totalGlobalDocuments">Cantidad de verificaciones: </Label>
                        <Input type="number" id="totalGlobalDocuments"
                               value={amountSelected.toString()}
                               onChange={e => {
                                   let number = Math.max(parseInt(e.target.value),0);
                                   setAmountSelected(number)
                                   handleVerificationsAmountChange(paymentType, number);
                               }} required/>
                    </FormGroup>
                    {
                        working ?
                            <div className="d-flex justify-content-center">
                                <Spinner color="primary"/>
                            </div> : null
                    }
                    Precio unitario: <MoneyFormat>{prices.precioUnitarioIva}</MoneyFormat> {tenant.currency}
                    <FormGroup>
                        <br/>
                        <SelectPaymentType value={paymentType} action="CONSUMABLE"
                                           type="verificaciones"
                                           onChange={handlePaymentTypeChange}
                                           pendingPayments={false}
                                           askFacturacion={false}
                                           paymentMethods={tenant.paymentMethods}/>
                    </FormGroup>
                    <FormGroup>
                        Precio total: <MoneyFormat>{prices.totalIva}</MoneyFormat> {tenant.currency}
                    </FormGroup>
                </ModalBody>
                {
                    error ? <React.Fragment>
                        <span style={{fontWeight: 'bold', color: 'red'}}>{error}</span>
                        <br/>
                        <br/>
                    </React.Fragment> : null
                }
                <ConfirmationModal
                    isOpen={showSpeiModal}
                    onOk={handleSpeiSubmit}
                    onCancel={handleSpeiCancel}
                    title={'Pago por SPEI'}
                >
                    <p>
                        Estás por comprar un paquete de <b>{amountSelected} verificaciones</b> usando <b>SPEI</b>. Al
                        seleccionar <b>'Comprar'</b> se asignarán las verificaciones y tendrás 3 días hábiles para realizar el pago por <b>SPEI</b>
                    </p>
                    <p>¿Deseas continuar?</p>
                </ConfirmationModal>
                <EmailNoticeSPEI
                    isOpen={noticeModal}
                    onClose={() => {
                        setShowSpeiModal(false)
                        setNoticeModal(false)
                        onSaved()
                    }}
                ></EmailNoticeSPEI>
                <ModalFooter>
                    <Button type="submit" disabled={working} color="primary">Pagar</Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>Cancelar</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )

}

export default BuyGlobalIdentityVerificationsModal;
