import React, {useState, useEffect, useRef} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, Spinner,Table} from 'reactstrap';
import MoneyFormat from '../shared/MoneyFormat';
import ConfirmationModal from '../shared/ConfirmationModal';
import StripeDocumentsModal from '../payments/StripeDocumentsModal';
import SelectPaymentType from '../payments/SelectPaymentType';
import 'rc-slider/assets/index.css';
import EmailNoticeSPEI from '../payments/EmailNoticeSPEI';

const defaultState = {
    totalGlobalDocuments: 0,
    monthly: false,
    paymentType: '',
    sub: 'Stripe0',
    mode: 'ONLY_DOCS'
    //timestamps: false
}

function BuyGlobalDocumentsModal({isOpen, onCancel, onSaved, companyActive, type, org, pendingPayments, askFacturacion, tenant}) {

    const [processingPayment, setProcessingPayment] = useState(false);
    const [showPaymentMethod, setShowPaymentMethod] = useState(true);


    const [error, setError] = useState('');
    const [working, setWorking] = useState(false);
    const [calcWorking, setCalcWorking] = useState(false);

    const [minimumNotReached, setMinimumNotReached] = useState(false);
    const [showConfModal, setShowConfModal] = useState(false);

    const [minimumCost, setMinimumCost] = useState(10); // SPEI minimum is $10 pesos
    const [months, setMonths] = useState(6);
    const [discount, setDiscount] = useState(0);

    const [buyConcept, setBuyConcept] = useState('Comprar paquete de documentos');
    const [buyConceptDetail, setBuyConceptDetail] = useState('documentos');

    const [paymentModal, setPaymentModal] = useState({
        open: false
    });

    const interval = useRef(setTimeout(() => {}, 0));


    const [buyState, setBuyState] = useState({
        totalGlobalDocuments: 0,
        monthly: false,
        paymentType: 'SPEI',
        sub: 'Stripe0',
        type: type
        //timestamps: false
    });

    const [resultState, setResultState] = useState({
        precioUnitario: 0,
        precioUnitarioMasIva: 0,
        precioPaquete: 0,
        precioPaqueteMasIva: 0,
        precioTotal: 0,
        precioTotalIva: 0,
        mensualidades: 0,
        mensualidadesMasIva: 0,
        pagoUnaSolaExhibicion: 0,
        pagoUnaSolaExhibicionMasIva: 0,
        precioUnitarioTimestampsIva: 0,
        precioPaqueteTimestampsIva: 0
    });

    const [noticeModal, setNoticeModal] = useState({
        open: false
    });


    function handleBuyStateChange(name) {
        return function(event) {
            let value;
            if(event.target.value >= 0 && event.target.value.length < 10) {
                value = event.target.value;
                setBuyState({...buyState, [name]:parseInt(value)});
            }
        }
    }

    function handleTimestampsCheckChange() {
        let newValue = !buyState.timestamps;
        setBuyState({...buyState, timestamps:newValue});

        if(!newValue) {
            setBuyConcept('Comprar paquete de documentos');
            setBuyConceptDetail('documentos');
        } else {
            setBuyConcept('Comprar paquete de documentos y sellos NOM151');
            setBuyConceptDetail('documentos y sellos NOM151');
        }
    }

    /*function handleSliderChange(value) {
        setVirtualTotalDocuments(value);
        let packageAmount = calculatePackageAmount(value);
        setDisplayTotalDocuments(packageAmount);
    }

    function handleSliderChangeEnd(value) {
        let packageAmount = calculatePackageAmount(value);
        setBuyState({...buyState, "totalGlobalDocuments":parseInt(packageAmount)});
    }*/

    /*function calculatePackageAmountType(value, type) {
        if(!type) {
            switch(value)
            {
                case 1: {
                    return 50;
                }
                case 2:{
                    return 100;
                }
                case 3: {
                    return 500;
                }
                case 4: {
                    return 1000;
                }
                case 5: {
                    return 2500;
                }
                case 6: {
                    return 5000;
                }
                case 7: {
                    return 10000;
                }
                default: {
                    return 0;
                }
            }
        } else {
            switch(value)
            {
                case 1: {
                    return 1000;
                }
                case 2:{
                    return 2500;
                }
                case 3: {
                    return 5000;
                }
                case 4: {
                    return 10000;
                }
                default: {
                    return 0;
                }
            }
        }
    }

    function calculatePackageAmount(value) {
        if(!buyState.monthly) {
            switch(value)
            {
                case 1: {
                    return 50;
                }
                case 2:{
                    return 100;
                }
                case 3: {
                    return 500;
                }
                case 4: {
                    return 1000;
                }
                case 5: {
                    return 2500;
                }
                case 6: {
                    return 5000;
                }
                case 7: {
                    return 10000;
                }
                default: {
                    return 0;
                }
            }
        } else {
            switch(value)
            {
                case 1: {
                    return 1000;
                }
                case 2:{
                    return 2500;
                }
                case 3: {
                    return 5000;
                }
                case 4: {
                    return 10000;
                }
                default: {
                    return 0;
                }
            }
        }
    }*/

    /*function updateOrgsValues(orgId) {
        return function(event) {
            let value;
            if(event.target.value.length < 10) {
                value = event.target.value;
                let newArray = buyState.orgsState;
                newArray[orgId].documentsAmount = value;
                setBuyState({...buyState, orgsState:newArray});
            }
        }
    }*/

    /*function handleMonthlyPaymentsChange(name) {
        return function(event) {
            let value = !buyState.monthly;*/

            /*if(value) {
                setMinimumValue(1000);
            } else {
                setMinimumValue(50);
            }*/

            /*if(value) {
                setMinimumValue(1000);
                setSliderMax(4);

                if(virtualTotalDocuments < 4) {
                    setVirtualTotalDocuments(1);
                    setDisplayTotalDocuments(1000);
                    setBuyState({...buyState, [name]:value, "totalGlobalDocuments": 1000});
                } else {
                    let newVirtualPosition = virtualTotalDocuments - 3;
                    setVirtualTotalDocuments(newVirtualPosition);
                    let packageAmount = calculatePackageAmountType(newVirtualPosition, value);
                    setDisplayTotalDocuments(packageAmount);
                    setBuyState({...buyState, [name]:value, "totalGlobalDocuments": packageAmount});
                }
            } else {
                setMinimumValue(50);
                setSliderMax(7);

                let newVirtualPosition = virtualTotalDocuments + 3;
                setVirtualTotalDocuments(newVirtualPosition);

                let packageAmount = calculatePackageAmountType(newVirtualPosition, value);
                setDisplayTotalDocuments(packageAmount);
                setBuyState({...buyState, [name]:value, "totalGlobalDocuments": packageAmount});
            }*/
        /*}
    }*/

    /*useEffect(() => {

        let orgsStateComplete = [];

        orgs.map((org) => {
            let o = {};
            o[org.id] = {id: org.id, name: org.name, documentsAmount: 0};
            orgsStateComplete.push(o);
            return null;
        });

        setBuyState((currentState) => {
            return {...currentState, orgsState: orgsStateComplete}
        });

    }, [orgs]);*/


    useEffect(() => {
        if(buyState.totalGlobalDocuments > 0) {
            //setUserInput(true);

            /*let minimumSet = 0;

            if(buyState.monthly) {
                minimumSet = 1000;
                setMinimumValue(1000);
            } else {
                minimumSet = 50;
                setMinimumValue(50);
            }*/

            //if(buyState.totalGlobalDocuments >= minimumSet) {
            setCalcWorking(true);
            //setMinimumDocuments(true);

            interval.current = setTimeout(() => {
                async function calculate() {
                    try {
                            const response = await httpPost('/calculateTotalDocuments', {
                                buyState
                            });

                            if(response) {
                                setResultState(previousState => {
                                    return {
                                        ...previousState,
                                        precioUnitario: response.data.data.precioUnitario,
                                        precioUnitarioMasIva: response.data.data.precioUnitarioIva,
                                        precioPaquete: response.data.data.precioPaquete,
                                        precioPaqueteMasIva: response.data.data.precioPaqueteIva,
                                        precioTotal: response.data.data.total,
                                        precioTotalIva: response.data.data.totalIva,
                                        mensualidades: response.data.data.mensualidad,
                                        mensualidadesMasIva: response.data.data.mensualidadIva,
                                        pagoUnaSolaExhibicion: response.data.data.soloPago,
                                        pagoUnaSolaExhibicionMasIva: response.data.data.soloPagoIva,
                                        precioUnitarioTimestampsMasIva: response.data.data.precioUnitarioTimestampsIva,
                                        precioPaqueteTimestampsMasIva: response.data.data.precioPaqueteTimestampsIva
                                    }

                                });
                            }

                            checkMininumThreshold( response.data.data.totalIva);
                            /*if(buyState.totalGlobalDocuments < 1000) {
                                setMonthlyMininumNotReached(true);
                            } else {
                                setMonthlyMininumNotReached(false);
                            }

                            if(buyState.totalGlobalDocuments < 50) {
                                setMininumNotReached(true);
                            } else {
                                setMininumNotReached(false);
                            }*/

                            //setChecked(true);
                            setCalcWorking(false);
                    } catch(e) {
                    }
                }

                calculate();
            }, 1000);
            /*} else {
                setCalcWorking(false);
                setMinimumDocuments(false);
            }*/
        } else {
            setResultState(previousState => {
                return {
                    ...previousState,
                    precioUnitario: 0,
                    precioUnitarioMasIva: 0,
                    precioPaquete: 0,
                    precioPaqueteMasIva: 0,
                    precioTotal: 0,
                    precioTotalIva: 0,
                    mensualidades: 0,
                    mensualidadesMasIva: 0,
                    pagoUnaSolaExhibicion: 0,
                    pagoUnaSolaExhibicionMasIva: 0
                }
            });
            //setMininumNotReached(true);
        }

        return () => {
            clearTimeout(interval.current);
        };
    }, [buyState]);

    /*useEffect(() => {
        if(tenant?.paymentMethods?.length > 1) {
            setShowPaymentMethod(true);
        }
    }, [tenant]);*/

    /*useEffect(() => {
        switch(mode) {
            case "UPGRADE": {
                setBuyState({...buyState, timestamps: true});
                setBuyConcept('Comprar paquete de documentos');
                setBuyConceptDetail('documentos');
                break;
            }
            case "ONLY_DOCS":
            default: {
                setBuyState({...buyState, timestamps: false});
                setBuyConcept('Comprar paquete de documentos');
                setBuyConceptDetail('documentos');
                break;
            }
        }
    }, [isOpen]);*/

    async function payItem(event) {
        event.preventDefault();
        event.stopPropagation();

        //let org = buyState.org;
        if(buyState.paymentType.includes("Stripe")) {
            // setPaymentModal({...paymentModal, open: true});
            const response = await httpPost(`/payments/documents/intentStripePayment`, { buyState })
            if (response.data.error) {
                setError(response.data.error);
            } else if(response.data.checkoutUrl) {
                window.location.href = response.data.checkoutUrl;
            }
        } else {
            handleSpeiPay();
        }
    }

    function modalClose() {
        //setChecked(false);
        //setUserInput(false);
        //setBuyState({...buyState, totalGlobalDocuments:0, monthly: false });
        setPaymentModal({...paymentModal, open: false});
    }

    function stripeApproved() {
        //setChecked(false);
        //setUserInput(false);
        //setBuyState({...buyState, totalGlobalDocuments:0, monthly: false});
        setPaymentModal({...paymentModal, open: false}); // message confirmation
        setBuyState(defaultState);
        onSaved();
    }

    useEffect(() => {
        async function processSpeiPayment() {
            try {
                //setShowConfModal(false);
                const response = await httpPost(`/orgs/globalDocuments`, {
                    buyState,
                    org
                })
                const {success, error} = response.data;
                if(success) {
                    setNoticeModal({...noticeModal, open: true});
                    //setBuyState(defaultState);
                    //setShowConfModal(false);
                    //onSaved();
                } else {
                    console.log(error);
                }
                setWorking(false);
                setProcessingPayment(false);
            } catch(e) {
                setWorking(false);
                setProcessingPayment(false);
            }

            //setVirtualTotalDocuments(1);
            //let packageAmount = calculatePackageAmountType(1, false);
            //setDisplayTotalDocuments(packageAmount);
        }

        if(processingPayment){
            processSpeiPayment();
        }
    }, [processingPayment])


    function handleSpeiPurchaseClick(){
        if(showConfModal) {
            setProcessingPayment(true);
        }
    }

    function noticeClose() {
        setBuyState(defaultState);
        setShowConfModal(false);
        setNoticeModal({...noticeModal, open: false});
        onSaved();
    }

    /*async function handleSpeiPurchase() {

        try {
            //setShowConfModal(false);
            const response = await httpPost(`/orgs/globalDocuments`, {
                buyState
            })
            const {success, error} = response.data;
            if(success) {
                setShowConfModal(false);
                setBuyState(defaultState);
                onSaved();

            } else {
                console.log(error);
            }
            setWorking(false);
        } catch(e) {
            setWorking(false);
        }
    }*/

    function handleConfCancel() {
        setWorking(false);
        setShowConfModal(false);
    }

    function handleSpeiPay(event) {
        setWorking(true);
        setShowConfModal(true);
    }

    function handleCancel() {
        //setChecked(false);
        //setUserInput(false);
        setBuyState(defaultState);

        //setMinimumValue(50);
        //setSliderMax(7);
        //setDisplayTotalDocuments(50);
        //setVirtualTotalDocuments(1);

        onCancel();
    }

    function handlePaymentTypeChange(type) {
        //setPaymentType(type);
        let monthly = false;
        let minimum = 10;
        let months = 6;
        let discount = 0;

        let paymentTypeCheck = "SPEI";

        switch(type.name){
            case "SPEI":
                discount = 10;
                break;
            case "Stripe0":
                switch (type.sub) {
                    case "Stripe0":
                        paymentTypeCheck = "Stripe0";
                        discount = 6;
                        break;
                    case "Stripe6":
                        paymentTypeCheck = "Stripe6";
                        discount = 3;
                        minimum = 600;
                        monthly = true;
                        break;
                    case "Stripe12":
                        paymentTypeCheck = "Stripe12";
                        minimum = 1200;
                        monthly = true;
                        months = 12;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        checkMininumThreshold(resultState.precioTotalIva);
        setMinimumCost(minimum);
        setMonths(months);
        setDiscount(discount);

        setBuyState({...buyState, paymentType:paymentTypeCheck, monthly: monthly});
    }

    function checkMininumThreshold(totalIva) {
        if(totalIva < minimumCost && totalIva !== 0) {
            setMinimumNotReached(true);
        } else {
            setMinimumNotReached(false);
        }
    }

    return (
        <Modal isOpen={isOpen}>
            <Form onSubmit={payItem}>
                <ModalHeader>
                    Comprar documentos
                </ModalHeader>
                <ModalBody>
                    <div>
                        {
                            pendingPayments ? <React.Fragment>
                                <div style={{marginBottom:'2%', fontWeight: 'bold', color: 'red'}}>Hay un pago pendiente de compra de documentos. No podrá comprar más documentos hasta que realice el pago.</div>
                            </React.Fragment> : null
                        }
                        {
                            askFacturacion ? <React.Fragment>
                                <div style={{marginBottom:'2%', fontWeight: 'bold', color: 'red'}}>No cuenta con un contacto de facturación. Por favor, vaya a la sección de "Facturación y soporte" para agregar uno.</div>
                            </React.Fragment> : null
                        }
                        {
                            buyState.type === "UPGRADE" ? <React.Fragment>
                                <div style={{marginBottom:'2%'}}>Una vez completada la compra de documentos, su cuenta será convertida a ON DEMAND.</div>
                            </React.Fragment> : null
                        }
                        <div style={{marginBottom:'2%'}}><b>Todos los precios tienen IVA incluido.</b> Los documentos que compres expiran <b>15</b> meses después de su compra.</div>
                    </div>
                    <FormGroup tag="fieldset">
                        <FormGroup>
                            <Label for="totalGlobalDocuments">Cantidad de documentos: </Label>
                            <Input disabled={pendingPayments || askFacturacion } type="number" id="totalGlobalDocuments" value={buyState.totalGlobalDocuments} onChange={handleBuyStateChange('totalGlobalDocuments')} required/>
                        </FormGroup>
                        {
                            !companyActive ? <React.Fragment>
                                <p style={{fontWeight: 'bold', color: 'red'}}>Su compañía ha sido desactivada. Por favor, contáctenos para más información.</p>
                            </React.Fragment> : null
                        }
                        {
                            calcWorking ? <React.Fragment>
                                { /* calcWorking */}
                                <div className="d-flex justify-content-center">
                                    <Spinner color="primary" />
                                </div>
                            </React.Fragment> : null
                        }
                        Precio unitario: <MoneyFormat>{resultState.precioUnitarioMasIva}</MoneyFormat> { tenant.currency }
                        <br/>
                        El precio unitario se reduce dependiendo de la cantidad de documentos a comprar y la forma de pago.
                        <br/>
                        {/*<MoneyFormat>{resultState.precioTotal}</MoneyFormat> - Con IVA: */}
                    </FormGroup>
                    {/*<FormGroup check>
                        <Label check>
                            <Input disabled={calcWorking || working || !companyActive } type="checkbox" id="monthly" value={buyState.monthly} checked={buyState.monthly} onChange={handleMonthlyPaymentsChange('monthly')} />{' '}
                            {
                                calcWorking || working || !companyActive ? <React.Fragment>
                                    <p style={{color: '#DADADA'}}>Pagar en mensualidades</p>
                                </React.Fragment> : <React.Fragment>
                                    <p>Pagar en mensualidades</p>
                                </React.Fragment>
                            }
                        </Label>
                    </FormGroup>*/}
                    {
                        showPaymentMethod ? <FormGroup>
                            <div>
                                <SelectPaymentType value={buyState.paymentType} subValue={buyState.sub} action="CONSUMABLE" type="documentos" onChange={handlePaymentTypeChange} pendingPayments={pendingPayments} askFacturacion={askFacturacion} paymentMethods={tenant.paymentMethods} />
                            </div>
                        </FormGroup> : null
                    }
                    <FormGroup>
                        Precio total: <MoneyFormat>{resultState.precioTotalIva}</MoneyFormat> { tenant.currency }
                        {
                            buyState.monthly ? <React.Fragment>
                                <br/>
                                {/* <MoneyFormat>{resultState.mensualidades}</MoneyFormat> - Con IVA: */}
                                {/*<MoneyFormat>{resultState.mensualidadesMasIva}</MoneyFormat>  */}
                                Mensualidades: <MoneyFormat>{resultState.mensualidadesMasIva}</MoneyFormat> { tenant.currency }
                                <br/>
                            </React.Fragment> : null
                        }
                        {/*<React.Fragment>
                        {/*<MoneyFormat>{resultState.pagoUnaSolaExhibicion}</MoneyFormat> - Con IVA:
                        Pago en una sola exhibición: <MoneyFormat>{resultState.pagoUnaSolaExhibicionMasIva}</MoneyFormat>
                        <br/>
                        </React.Fragment>*/}
                        {
                            minimumNotReached ? <React.Fragment>
                                <br/>
                                <p style={{fontWeight: 'bold', color: 'red'}}>La compra mínima a <b>{months}</b> meses, es de <MoneyFormat>{minimumCost}</MoneyFormat> { tenant.currency }</p>
                            </React.Fragment> : null
                        }
                    </FormGroup>
                </ModalBody>
                {
                    error ? <React.Fragment>
                        <span style={{fontWeight: 'bold', color: 'red'}}>{error}</span>
                        <br/>
                        <br/>
                    </React.Fragment> : null
                }
                <ModalFooter>
                    <Button type="submit" disabled={calcWorking || working || minimumNotReached || !buyState.paymentType || !companyActive || resultState.precioTotalIva <= 0 || pendingPayments} color="primary">Pagar</Button>
                    <Button disabled={calcWorking || working} color="secondary" onClick={handleCancel}>Cancelar</Button>
                </ModalFooter>
            </Form>
            <StripeDocumentsModal
                isOpen={paymentModal.open}
                buyState={buyState}
                months={months}
                discount={discount}
                onCancel={modalClose}
                onStripeApproved={stripeApproved}
                org={org}
                type={type}
                />
            <ConfirmationModal isOpen={showConfModal}
                onCancel={handleConfCancel}
                onOk={handleSpeiPurchaseClick}
                title={buyConcept}>
                    <p>
                        Estás por comprar un paquete de <b>{buyState.totalGlobalDocuments} {buyConceptDetail}</b> usando <b>SPEI</b>. Al seleccionar <b>'Comprar'</b> se
                        asignarán los {buyConceptDetail} y tendras 3 días hábiles para realizar el pago por <b>SPEI</b></p>
                    <p>¿Deseas continuar?</p>
            <EmailNoticeSPEI
                isOpen={noticeModal.open}
                onClose={noticeClose}
            />
            </ConfirmationModal>
        </Modal>
    )
}

export default BuyGlobalDocumentsModal
