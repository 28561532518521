import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom'
import axios from 'axios';
import {httpGet, backend} from '../shared/Config';
import Section from '../shared/Section'
import DateField from '../shared/DateField';
import {Button} from 'reactstrap'
import InvoiceDataModal from './InvoiceDataModal'
import SupportDataModal from '../supportData/SupportDataModal'
import SectionList from '../shared/SectionList'
import SelectGlobalInvoiceData from '../shared/SelectGlobalInvoiceData';
import SelectSupportData from '../shared/SelectSupportData';
import SelectGlobalSupportData from '../shared/SelectGlobalSupportData';


function InvoiceDataList() {

    const [invoiceDataList, setInvoiceDataList] = useState([])
    const [invoiceModal, setInvoiceModal] = useState({
        open: false,
        initialState: null
    });

    const [supportDataList, setSupportDataList] = useState([]);
    const [supportModal, setSupportModal] = useState({
        open: false,
        initialState: null
    });

    const [invoiceData, setInvoiceData] = useState({
        working: false
    })
    const [supportData, setSupportData] = useState({
        working: false
    })
    

    /*useEffect(() => {
        async function fetchAllInvoiceData() {
            try {
                const response = await axios.get(backend('/invoiceData'));
                setInvoiceDataList(response.data);
            } catch(e) {
                console.error(e);
                setInvoiceDataList([]);
            }
        }

        fetchAllInvoiceData();
    }, [])

    useEffect(() => {
        async function fetchAllSupportData() {
            try {
                const response = await axios.get(backend('/supportData'));
                setSupportDataList(response.data);
            } catch(e) {
                console.error(e);
                setInvoiceDataList([]);
            }
        }

        fetchAllSupportData();
    }, [])*/

    /*function handleInvoiceDataSaved(iData) {
        if(invoiceModal.initialState) {
            setInvoiceDataList(invoiceDataList.map(i => i.id === iData.id ? iData : i));
        } else {
            setInvoiceDataList([iData, ...invoiceDataList])
        }
        setInvoiceModal({initialState: null, open: false})        
    }*/

    /*function handleSupportDataSaved(iData) {
        if(supportModal.initialState) {
            setSupportDataList(supportDataList.map(i => i.id === iData.id ? iData : i));
        } else {
            setSupportDataList([iData, ...supportDataList])
        }
        setSupportModal({initialState: null, open: false})        
    }*/

    /*function handleEdit(iData) {
        return function() {
            setInvoiceModal({initialState: iData, open: true})            
        }
    }

    function handleInvoiceDataChange(invoiceD) {
        setInvoiceData(invoiceD);
    }

    function handleSupportDataChange() {

    }*/

    return (
        <div>
            <h3>Facturación y soporte</h3>
            <br/>
            En este menú puedes actualizar tus contactos de facturación y soporte. El contacto de facturación es el correo electrónico al que llegaran todos los correos
            relacionados con compras de documentos/sellos NOM151, renovación de suscripciones y creación de usuarios. El contacto de soporte es el correo electrónico
            que será nuestro primer medio de comunicación con usted o su compañía.
            <br/>
            <div className="row">
                <div className="col">
                    <Section>
                        <div className="col">
                            <SelectGlobalInvoiceData
                                disabled={invoiceData.working}/>         
                                {/*onChange={handleInvoiceDataChange}*/}                  
                        </div>
                    </Section>
                    <Section>
                        <div className="col">
                            <SelectGlobalSupportData
                                disabled={supportData.working}/>   
                                {/*onChange={handleSupportDataChange}*/}                 
                        </div>
                        {/*<SupportDataModal
                            isOpen={supportModal.open}
                            initialState={supportModal.initialState}
                            onCancel={() => setSupportModal({initialState: null, open: false})}
                            onSaved={handleSupportDataSaved}/>*/}
                    </Section>
                </div>
            </div>
            {/*<div className="row">
                <div className="col">
                    <Section>
                        <h4>Contacto de Soporte</h4>
                        <br/>
                        <div className="col">
                            <h4>Soporte</h4>
                            <br/>
                            <div className="column">
                                <SelectSupportData
                                    onChange={handleSupportDataChange}
                                    disabled={supportData.working}/>
                                <p>
                                    Nombre: {supportData.supportName} <br/>
                                    Correo: {supportData.supportEmail} <br/>
                                    Telefono: {supportData.supportPhone}                 
                                </p>                                      
                            </div>                                 
                        </div>
                    </Section>
                </div>
            </div>*/}
            {/*<div className="row">
                <div className="col">
                    <h3>Libreta contactos de facturación</h3>
                    <div className="row justify-content-end">
                        <Button color="primary" onClick={handleAdd}>Agregar</Button>
                    </div>
                    <SectionList collection={invoiceDataList} noData={<p>Sin información</p>}>
                        {
                            invoiceDataList.map(iData => (
                                <div key={iData.id}>
                                    <p style={{fontWeight:'bold'}}>{iData.rfc}</p>
                                    <p>
                                        {iData.name} <br/>
                                        {iData.contactName} <br/>
                                        {iData.contactEmail} <br/>
                                        {iData.contactPhone} <br/>
                                        {iData.postalCode} <br/>
                                    </p>
                                    <div className="row justify-content-end">
                                        <Button color="primary" onClick={handleEdit(iData)}>Editar</Button>
                                    </div>
                                </div>
                            ))
                        }
                    </SectionList>
                </div>
                <InvoiceDataModal
                    isOpen={invoiceModal.open}
                    initialState={invoiceModal.initialState}
                    onCancel={() => setInvoiceModal({initialState: null, open: false})}
                    onSaved={handleInvoiceDataSaved}/>
            </div>
            <div className="col">
                <h3>Libreta contactos de soporte</h3>
                    </div>*/}
        </div>
        
    )
}

export default InvoiceDataList;
