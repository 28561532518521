import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {httpGet, backend} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap'

const defaultData = {
    name: '',
    rfc: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    postalCode: '',
    cfdiId: '',
    regimenFiscal: ''
}

function InvoiceDataModal({isOpen, initialState, onCancel, onSaved}) {
    const [iData, setIData] = useState(defaultData)
    const [working, setWorking] = useState(false);

    useEffect(() => {
        setIData(initialState || defaultData);
    }, [isOpen, initialState])
    
    function handleCancel() {
        onCancel();
    }
    function handleTextChange(attr) {
        return function(event) {
            if(attr !== "cfdi") {
                setIData({...iData, [attr]:event.target.value})
            } else {
                setIData({...iData, [attr]:event.target.value, regimenFiscal: ""})
            }
        }
    }

    const [regimenFiscalesSelect, setRegimenFiscalesSelect] = useState();
    const [cfdiSelect, setCfdiSelect] = useState();


    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                const response = await httpGet(`/getCFDIs`);
                setCfdiSelect(response.data.map((cfdiId) => <option key={cfdiId.id} value={cfdiId.id}>{cfdiId.desc}</option>));
            } catch(e) {
            }     
            
        }
        fetchRegimenFiscales();
    }, [isOpen])

    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                const response = await httpGet(`/getRegimenFiscales/${iData.cfdiId.id}`);
                setRegimenFiscalesSelect(response.data.map((regimenFiscal) => <option key={regimenFiscal.id} value={regimenFiscal.code}>{regimenFiscal.data}</option>));
            } catch(e) {
            }
        }
        if(iData.cfdiId) {
            fetchRegimenFiscales();
        }
    }, [iData.cfdiId])

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await axios.post(backend('/invoiceData'), iData);
            setWorking(false);
            onSaved(response.data);
        } catch(e) {
            setWorking(false);
        }

    }

    return (
        <Modal isOpen={isOpen}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    Información de facturación
                </ModalHeader>
                <ModalBody>                
                    <FormGroup>
                        <Label for="name">Razón social</Label>
                        <Input type="text" id="name" value={iData.name} onChange={handleTextChange('name')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="rfc">RFC</Label>
                        <Input type="text" id="rfc" value={iData.rfc} onChange={handleTextChange('rfc')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="nombreFacturacion">Nombre</Label>
                        <Input type="text" id="nombreFacturacion" value={iData.contactName} onChange={handleTextChange('contactName')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Correo electrónico</Label>
                        <Input type="email" id="emailFacturacion" value={iData.contactEmail} onChange={handleTextChange('contactEmail')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="telefonoFacturacion">Telefono</Label>
                        <Input type="number" id="telefonoFacturacion" value={iData.contactPhone} onChange={handleTextChange('contactPhone')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="postalCode">Código postal</Label>
                        <Input type="text" id="postalCode" value={iData.postalCode} onChange={handleTextChange('postalCode')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="CFDI">Uso del CFDI</Label>
                        <Input id="cfdi" type="select" value={iData.cfdiId.id} onChange={handleTextChange('cfdiId')} required>
                            <option value=""></option>
                            {cfdiSelect}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="regimenFiscal">Régimen fiscal</Label>
                        <Input disabled={working} id="regimenFiscal" type="select" value={iData.regimenFiscal.code} onChange={handleTextChange('regimenFiscal')} required>
                            <option value=""></option>
                            {regimenFiscalesSelect}
                        </Input>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" disabled={working} color="primary">Guardar</Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>Cancelar</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default InvoiceDataModal;