import {useEffect, useState} from "react";
import {Alert} from "reactstrap";
import {httpGet} from "../shared/Config";

export default function StripeSessionData() {

    const [stripeStatus, setStripeStatus] = useState(null)
    const [sessionStatus, setSessionStatus] = useState(null)

    useEffect(() => {

        async function fetchSessionStatus(sessionId) {
            const res = await httpGet(`/payments/session?sessionId=${sessionId}`);
            if(res.data.error) {
                setSessionStatus(res.data.error)
            } else {
                if(res.data.status === 'complete') {
                    setSessionStatus(`Tu pago se ha completado`)
                } else {
                    setSessionStatus(`Tu pago se encuentra en proceso`)
                }
            }
        }

        const searchParams = new URLSearchParams(window.location.search);
        if(searchParams.get('stripe_success') === 'true') {
            setStripeStatus('success')
            fetchSessionStatus(searchParams.get('session_id'))
        }
    }, []);

    return (
       stripeStatus ?
       <Alert color="info">
            <h4>Muchas gracias por tu compra</h4>
           {
               sessionStatus ?
                   <div>{sessionStatus}</div>
                   : null
           }
       </Alert>
           : null
    )

}
