import React, {useEffect, useState} from 'react';
import Section from '../shared/Section'
import {Redirect} from 'react-router-dom'
import {httpGet} from '../shared/Config'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faAd, faFileInvoiceDollar, faWindowRestore } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

function MenuIcon({icon, name, to, description}) {
    return (
        <div className="column" style={{textAlign:'center'}}>
            <Link to={to}>                
                    <FontAwesomeIcon className="MenuIcon" icon={icon}/>
                    <div style={{color:'black', fontWeight:500}}>{name}</div>                                
            </Link>
            <div style={{maxWidth:'200px', fontSize:'12px', paddingTop:'15px'}}>{description}</div>
        </div>       
    )
}

function MenuIconOut({icon, name, to, description}) {

    function navigate() {
        window.location(to);
    }

    return (
        <div className="column" style={{textAlign:'center'}}>
            <Link onClick={navigate}>                
                    <FontAwesomeIcon className="MenuIcon" icon={icon}/>
                    <div style={{color:'black', fontWeight:500}}>{name}</div>                                
            </Link>
            <div style={{maxWidth:'200px', fontSize:'12px', paddingTop:'15px'}}>{description}</div>
        </div>       
    )
}

function ConsoleMenu() {

    const [company, setCompany] = useState(null);

    useEffect(() => {
        async function fetchOrgs() {
            try {
                const responseAdmin = await httpGet(`/companyData`);
                setCompany(responseAdmin.data.data)
            } catch(e) {
                console.error(e);
            }
        }

        fetchOrgs();
    }, [])


    return (
        <React.Fragment>
            {
                company === null ?
                'Cargando...' 
                :
                !company.initialSetup ?
                <Redirect to="/console/orgs/new"></Redirect>
                :
                <Section>
                    <div className="row justify-content-around">
                        <MenuIcon icon={faUsers} name="Mi cuenta" 
                            to={`/console/orgs`}
                            description="Accede a los detalles de tu cuenta como usuarios o documentos restantes"/>
                        <MenuIcon icon={faBook} name="Información de pagos" 
                            to="/console/payments"
                            description="Detalle de pagos pendientes e historial de pagos realizados"/>
                        <MenuIcon icon={faFileInvoiceDollar} name="Facturación" 
                            to="/console/invoiceData"
                            description="Tu información de facturación"/>
                        <MenuIcon icon={faAddressBook} name="Soporte" 
                            to="/console/supportData"
                            description="Tus contactos de soporte"/>
                    </div>
                </Section> 
            }            
        </React.Fragment>        
        
    )
}

export default ConsoleMenu;

